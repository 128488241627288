import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EachPageHeading from "../../components/Common/EachPageHeading";
import Spinner from "../../components/Common/Spinner";
import { Row, Col } from "react-bootstrap";
import "./style.css";
import CollectionNewsListBox from "../../components/Common/CollectionNewsListBox";
import { API_URL, header } from "../../utils/config";
import ErrorBoundary from "../../components/Error/ErrorBoundary";
import axios from "axios";
import { MetaHead } from '../../components/Common/MetaHead.js';

const CitizenCharter = ({ engLang }) => {
  const [citizencharter, setCitizenCharter] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    citizenCharter();
  }, []);

  const citizenCharter = () => {
    axios
      .get(`${API_URL}api/citizen_charter`, header)
      .then((res) => {
        setCitizenCharter(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setError(true);
      });
  };

  const content = () => {
    if (loader) return <Spinner />;
    if (error) return <ErrorBoundary />;
    if (citizencharter) {
      return (
        <div>
        <div class="d-flex justify-content-center">
         <a href={`${API_URL}${citizencharter.file}`} download>
            Download
          </a>
        </div>
          <img src={`${API_URL}${citizencharter.file}`} width="100%" />
         
        </div>
      );
    } else {
      return <h1>No About Data</h1>;
    }
  };

  const title = engLang ? "CITIZEN CHARTER" : "नागरिक बडापत्र";

  return (
    <CitizenCharterDiv>

      <Row>
        <Col xs={12} md={8} lg={8}>
          <EachPageHeading title={title} />
          <MetaHead page_name="नागरिक बडापत्र"  description="जिल्ला हुलाक कार्यालय सल्यान का नागरिक बडापत्र हेर्न सक्नु हुन्छ " page_title="जिल्ला हुलाक कार्यालय सल्यान को नागरिक बडापत्र" page_url="/department/citizen-charter"/>
          {content()}
        </Col>
        <Col>
          <CollectionNewsListBox type="News & Notice" engLang={engLang} />
        </Col>
      </Row>
    </CitizenCharterDiv>
  );
};

export default CitizenCharter;

const CitizenCharterDiv = styled.div`
  padding: 10px;
  .lead-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
  }
`;
