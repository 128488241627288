import React from "react";
import { ActivitiesDiv } from "./Activities.styled";
import EachPageHeading from "../../components/Common/EachPageHeading";
import { Row, Col } from "react-bootstrap";
import CollectionNewsListBox from "../../components/Common/CollectionNewsListBox";
import { Helmet } from "react-helmet";
import Image from "../../utils/assets/logo.png";
import { API_URL, header } from "../../utils/config";
import { MetaHead } from '../../components/Common/MetaHead.js';

const Activities = (props) => {
  
  return (
    <ActivitiesDiv>
      <MetaHead page_name="क्रियाकलापहरू"  description="जिल्ला हुलाक कार्यालय सल्यान का क्रियाकलापहरू हेर्न सक्नु हुन्छ " page_title="जिल्ला हुलाक कार्यालय सल्यान का क्रियाकलापहरू" page_url="/activities"/>
      <Row>
        <Col xs={12} md={8} lg={8}>
          <EachPageHeading title={`Activities`} />
          <p>activity 12</p>
        </Col>
        <Col>
          <CollectionNewsListBox type="News & Notice" engLang={props.engLang} />
        </Col>
      </Row>
    </ActivitiesDiv>
  );
};

export default Activities;
