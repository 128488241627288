import React, { useState, useEffect } from "react";
import { PostalRatesSection } from "./PostalRates.styled";
import EachPageHeading from "../../components/Common/EachPageHeading";
import { Col, Row } from "react-bootstrap";
import FileAndDownloadTable from "../../components/Common/FileAndDownloadTable";
import Spinner from "../../components/Common/Spinner";
import CollectionNewsListBox from "../../components/Common/CollectionNewsListBox";
import { API_URL, header } from "../../utils/config";
import ErrorBoundary from "../../components/Error/ErrorBoundary";
import axios from "axios";
import { MetaHead } from '../../components/Common/MetaHead.js';

const PostalRates = (props) => {
  const [postalRates, setPostalRates] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadPostalRates();
  }, []);

  const loadPostalRates = () => {
    axios
      .get(`${API_URL}api/postal_rates`, header)
      .then((res) => {
        setPostalRates(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setError(true);
      });
  };

  const content = () => {
    if (loader) return <Spinner />;
    if (error) return <ErrorBoundary />;
    if (postalRates) {
      return <FileAndDownloadTable data={postalRates} />;
    } else {
      return <h1>No Postal Rates Data</h1>;
    }
  };

  return (
    <PostalRatesSection>
      <Row>
        <Col xs={12} md={8} lg={8}>
          <EachPageHeading
            title={props.engLang ? "Postal Rates" : "हुलाक दर"}
          />
          <p className="lead">Download the attachments.</p>
          <MetaHead page_name="हुलाक दर"  description="जिल्ला हुलाक कार्यालय सल्यान को हुलाक दर हेर्न सक्नु हुन्छ " page_title="जिल्ला हुलाक कार्यालय सल्यान को हुलाक दर" page_url="/postal-rates"/>
      
          {content()}
        </Col>
        <Col>
          <CollectionNewsListBox type="News & Notice" engLang={props.engLang} />
        </Col>
      </Row>
    </PostalRatesSection>
  );
};

export default PostalRates;
