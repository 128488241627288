import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import EachPageHeading from "../../components/Common/EachPageHeading";
import FileAndDownloadTableAct from "../../components/Common/FileAndDownloadTableAct";
import Spinner from "../../components/Common/Spinner";
import CollectionNewsListBox from "../../components/Common/CollectionNewsListBox";
import { API_URL, header, APP_NAME, FB_ID } from "../../utils/config";
import ErrorBoundary from "../../components/Error/ErrorBoundary";
import axios from "axios";
import { MetaHead } from '../../components/Common/MetaHead.js';

const ActRegulation = (props) => {
  const [actregulation, setActRegulation] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getActRegulation();
  }, []);

  const getActRegulation = () => {
    axios
      .get(`${API_URL}api/act_and_regulation`, header)
      .then((res) => {
        setActRegulation(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setError(true);
      });
  };

  const content = () => {
    if (loader) return <Spinner />;
    if (error) return <ErrorBoundary />;
    if (actregulation) {
      return <FileAndDownloadTableAct data={actregulation} />;
    } else {
      return <h1>No Act And Regulation Data</h1>;
    }
  };

  return (
    <ActRegulationDiv>
    <MetaHead page_name="ऐन तथा नियमावली"  description="जिल्ला हुलाक कार्यालय सल्यान का ऐन तथा नियमावली हेर्न सक्नु हुन्छ " page_title="जिल्ला हुलाक कार्यालय सल्यान का ऐन तथा नियमावली" page_url="/act-regulations"/>
      
      <Row>
        <Col xs={12} md={8} lg={8}>
          <EachPageHeading
            title={props.engLang ? "Act And Regulation" : "ऐन तथा नियमावली"}
          />
          <p className="lead">Download the attachments.</p>
          {content()}
        </Col>
        <Col>
          <CollectionNewsListBox type="News & Notice" engLang={props.engLang} />
        </Col>
      </Row>
    </ActRegulationDiv>
  );
};

export default ActRegulation;

const ActRegulationDiv = styled.div`
  padding: 10px;
`;
