import React, { useState, useEffect } from "react";
import { HomeStyled } from "./Home.styled";
import HomeCarousel from "./HomeCarousel";
import { Card, Row, Col, CardDeck, Button } from "react-bootstrap";
import EachPageHeading from "../../components/Common/EachPageHeading";
import HomePageNews from "../../components/NewsListBox/HomePageNews";
import Spinner from "../../components/Common/Spinner";
import { FormattedMessage } from "react-intl";
import Highlight from "./Highlight";
import ErrorBoundary from "../../components/Error/ErrorBoundary";
import { FacebookProvider, Page } from "react-facebook";
import { Content } from "../../components/Common/Content";
import { API_URL,APP_URL, APP_NAME, FB_ID } from "../../utils/config";
import axios from "axios";
import { MetaHead } from '../../components/Common/MetaHead.js';

const Home = (props) => {
  // news state
  const [homepage, setHomepage] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadHomepage();
  }, [loader, error]);

  const loadHomepage = () => {
    axios
      .get(`${API_URL}api/homepage`)
      .then((res) => {
        setHomepage(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setError(true);
      });
  };
  const navigate = (to) => props.history.push(to);

  const newsandnotice = props.engLang ? "NOTICES & NEWS" : "समाचार तथा सूचना";
  const tendernotice = props.engLang ? "TENDER NOTICE" : "बोलपत्र";
  const circularnotice = props.engLang ? "CIRCULAR" : "परिपत्र";

  const content = () => {
    if (loader) return <Spinner />;
    if (error) return <ErrorBoundary />;
    if (homepage) {
      return (
        <div className="carousel-wrapper">
          <HomeCarousel sliders={homepage.sliders} />

          <Highlight engLang={props.engLang} highlights={homepage.highlights} />
          <div className="services">
            <div class="row">
              <div class="col-md-9">
                <CardDeck className="news">
                  <Card>
                    <HomePageNews
                      pill={true}
                      type="news"
                      pillText={newsandnotice}
                      data={homepage.news}
                      engLang={props.engLang}
                    />
                    <div className="readmore">
                      <Button
                        className="primary"
                        onClick={() => navigate("/media/news")}
                      >
                        {props.engLang ? "View All" : "सबै हेर्नुहोस्"}
                      </Button>
                    </div>
                  </Card>
                  <Card>
                    <HomePageNews
                      pill={true}
                      type="news"
                      pillText={tendernotice}
                      data={homepage.tenders}
                      engLang={props.engLang}
                    />

                    <div className="readmore">
                      <Button
                        className="primary "
                        onClick={() => navigate("/media/tender")}
                      >
                        {props.engLang ? "View All" : "सबै हेर्नुहोस्"}
                      </Button>
                    </div>
                  </Card>
                  <Card>
                    <HomePageNews
                      pill={true}
                      type="news"
                      pillText={circularnotice}
                      data={homepage.circular}
                      engLang={props.engLang}
                    />
                    <div className="readmore">
                      <Button
                        className="primary"
                        onClick={() => navigate("/media/circular")}
                      >
                        {props.engLang ? "View All" : "सबै हेर्नुहोस्"}
                      </Button>
                    </div>
                  </Card>
                </CardDeck>
              </div>
              <div class="col-md-3">
                {homepage.staffs &&
                  homepage.staffs.map((staff, i) =>
                    i == 0 ? (
                      <Card className="director-card">
                        <img
                          className="director-img"
                          style={{
                            borderRadius: "50%",
                          }}
                          src={`${API_URL}${staff.file}`}
                        />
                        <div className="director-desc">
                          <h5>{staff.name}</h5>
                          <p>कार्यलय प्रमुख</p>
                          <p>{staff.phone}</p>
                        </div>
                      </Card>
                    ) : (
                      ""
                    )
                  )}
              </div>
            </div>
            <div style={{ float: "left" }}>
              <EachPageHeading
                title={props.engLang ? "Our Servies" : "हाम्रा सेवाहरु"}
              />
            </div>
            <hr />
            <div>
              <Row>
                <Col md={9}>
                  <Row>
                    <CardDeck>
                      {homepage.services &&
                        homepage.services.map((service, index) => (
                          <Col md={4} key={index}>
                            <Card style={{ margin: "0" }}>
                              <Card.Img
                                variant="top"
                                src={`${API_URL}${service.file}`}
                                style={{ height: "150px;" }}
                              />
                              <Card.Body style={{ minHeight: "230px" }}>
                                <Card.Title>
                                  {props.engLang
                                    ? service.title_english
                                    : service.title_nepali}
                                </Card.Title>
                                <Card.Text>
                                  <Content
                                    dangerouslySetInnerHTML={{
                                      __html: `${
                                        props.engLang
                                          ? service.description_english.substring(
                                              0,
                                              100
                                            ) + "..."
                                          : service.description_nepali.substring(
                                              0,
                                              100
                                            ) + "..."
                                      }`,
                                    }}
                                  ></Content>
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer>
                                <Button
                                  className="primary"
                                  onClick={() =>
                                    navigate("/services/" + service.id)
                                  }
                                >
                                  Read More
                                </Button>
                              </Card.Footer>
                            </Card>
                          </Col>
                        ))}
                    </CardDeck>
                  </Row>
                </Col>

                <Col md={3}>
                  {homepage.staffs &&
                    homepage.staffs.map((staff, i) =>
                      i == 1 ? (
                        <Card className="director-card">
                          <img
                            style={{
                              borderRadius: "50%",
                            }}
                            className="director-img"
                            src={`${API_URL}${staff.file}`}
                          />
                          <div className="director-desc">
                            <h5>{staff.name}</h5>
                            <p>नायब सुब्बा/सूचना अधिकारी</p>
                            <p>{staff.phone}</p>
                          </div>
                        </Card>
                      ) : (
                        ""
                      )
                    )}
                </Col>
              </Row>
              <Row>
                <Button
                  className="primary"
                  onClick={() => navigate("/services")}
                >
                  {props.engLang
                    ? "See All Servies"
                    : "सबै सेवाहरु हेर्नु होस् "}
                </Button>
              </Row>
            </div>
          </div>
          <br />
          <CardDeck className="home-last-section">
            <Card onClick={() => navigate("/gallery")}>
              <EachPageHeading
                title={
                  <FormattedMessage
                    id="home.ourgallery"
                    defaultMessage="Our Gallery"
                  />
                }
              />
              <Card.Img></Card.Img>
              <Card.Body>Photos from World Post Day 2019</Card.Body>
            </Card>
            <Card onClick={() => navigate("/publications")}>
              <EachPageHeading
                title={
                  <FormattedMessage
                    id="home.ourpublication"
                    defaultMessage="Our Publications"
                  />
                }
              />
              <Card.Img></Card.Img>
              <Card.Body>Our Publication</Card.Body>
            </Card>
            <Card>
              <EachPageHeading
                title={
                  <FormattedMessage
                    id="home.ourfacebook"
                    defaultMessage="Our Facebook"
                  />
                }
              />
              <Card.Img></Card.Img>
              <Card.Body>

                <FacebookProvider appId="212716196805650">
                  <Page href="https://www.facebook.com" tabs="timeline" />
                </FacebookProvider>
              </Card.Body>
            </Card>
          </CardDeck>
        </div>
      );
    } else {
      return <h1>No About Data</h1>;
    }
  };

  

  return <HomeStyled><MetaHead page_name="गृहपृष्ठ"  description="जिल्ला हुलाक कार्यालय सल्यान को  गृहपृष्ठ हेर्न सक्नु हुन्छ " page_title="जिल्ला हुलाक कार्यालय सल्यान को  गृहपृष्ठ" page_url="/home"/>
       {content()}</HomeStyled>;
};

export default Home;
