import React from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import { ReactComponent as Pdf } from "../../assets/images/pdf.svg";
import { ReactComponent as Download } from "../../assets/images/download.svg";
import { API_URL,APP_URL, APP_NAME, FB_ID } from "../../utils/config";
import Image from "../../utils/assets/logo.png";

const NewsTable = ({ engLang, news, type }) => {
 
  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Title</th>
            <th>Download</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {news ? (
            news.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td>
                    <a href={`${API_URL}${item.file}`} download>
                      <Download width={40} height={40} className="mr-1" />
                      <span style={{ fontSize: "12px" }}>download</span>
                    </a>
                  </td>
                  <td>{item.nepali_date}</td>
                </tr>
              );
            })
          ) : (
            <div
              style={{
                textAlign: "center",
                paddingTop: "2em",
                paddingBottom: "2em",
              }}
              className="d-flex justify-content-center"
            >
              {engLang ? "No data found" : "कुनै फेला परेन"}
            </div>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default NewsTable;
