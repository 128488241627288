import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EachPageHeading from "../../components/Common/EachPageHeading";
import VericleTimeline from "../../components/Common/VericleTimeline";
import Pagination from "react-js-pagination";
import { API_URL } from "../../utils/config";
import axios from "axios";
import ErrorBoundary from "../../components/Error/ErrorBoundary";
import Spinner from "../../components/Common/Spinner";
import NewsTable from "../../components/Common/NewsTable";
import { MetaHead } from '../../components/Common/MetaHead.js';

const Tender = (props) => {
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(3);
  const [news, setNews] = useState({});
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [month, setMonth] = useState("01");
  const [year, setYear] = useState(2067);

  const handlePageChange = (pageNumber) => {
    axios
      .get(`${API_URL}api/tenders?page=` + pageNumber)
      .then((res) => {
        setNews(res.data.data);
        setActivePage(res.data.current_page);
        setItemsCountPerPage(res.data.per_page);
        setTotalItemsCount(res.data.total);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setError(true);
      });
  };

  useEffect(() => {
    loadNews();
  }, []);

  const loadNews = () => {
    axios
      .get(`${API_URL}api/tenders`)
      .then((res) => {
        setNews(res.data.data);
        setActivePage(res.data.current_page);
        setItemsCountPerPage(res.data.per_page);
        setTotalItemsCount(res.data.total);
        setLoader(false);
      })
      .catch((err) => {
        setError(true);
        setLoader(false);
      });
  };

  const loadNewsByDateMonth = (month, year) => {
    setLoader(true);
    axios
      .post(`${API_URL}api/tenders`, { year: year, month: month })
      .then((res) => {
        setNews(res.data);
        setActivePage(res.data.current_page);
        setItemsCountPerPage(res.data.per_page);
        setTotalItemsCount(res.data.total);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const yearHandler = (name) => (e) => {
    setYear(e.target.value);
    loadNewsByDateMonth(month, e.target.value);
  };
  const monthHandler = (name) => (e) => {
    setMonth(e.target.value);
    console.log(year);
    loadNewsByDateMonth(e.target.value, year);
  };

  const content = () => {
    if (loader) return <Spinner />;
    if (error) return <ErrorBoundary />;
    return (
      <TenderDiv>
        <EachPageHeading title={props.engLang ? "Tender Notice" : "बोलपत्र"} />
        <div>
          <div className="float-right mb-2">
            <div className="float-left mr-3">
              <select
                className="form-control"
                value={year}
                onChange={yearHandler("year")}
              >
                <option value="2076">2076</option>
                <option value="2077">2077</option>
                <option value="2078">2078</option>
                <option value="2079">2079</option>
              </select>
            </div>

            <div className="float-right">
              <select
                className="form-control "
                value={month}
                onChange={monthHandler("month")}
              >
                <option value="01">बैशाख</option>
                <option value="02">जेठ</option>
                <option value="03">असार</option>
                <option value="04">श्रावण</option>
                <option value="05">भदौ</option>
                <option value="06">आश्विन</option>
                <option value="07">कार्तिक</option>
                <option value="08">मंसिर</option>
                <option value="09">पुष</option>
                <option value="10">माघ</option>
                <option value="11">फाल्गुन</option>
                <option value="12">चैत्र</option>
              </select>
            </div>
          </div>
          <NewsTable
            engLang={props.engLang}
            news={news}
            type="tender"
            page_title="बोलपत्र"
            url="/media/tender"
          />
        </div>

        <div className="d-flex justify-content-center">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={pageRangeDisplayed}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </TenderDiv>
    );
  };
  return <div><MetaHead page_name="बोलपत्र"  description="जिल्ला हुलाक कार्यालय सल्यान को  बोलपत्रहरु  हेर्न सक्नु हुन्छ " page_title="जिल्ला हुलाक कार्यालय सल्यान को  बोलपत्रहरु " page_url="/media/tender"/>
      {content()}</div>;
};

export default Tender;

const TenderDiv = styled.div`
  padding: 10px;
`;
