import React, { useState, useEffect } from "react";
import { RightToInformationSection } from "./RightToInformation.styled";
import EachPageHeading from "../../components/Common/EachPageHeading";
import { Col, Row } from "react-bootstrap";
import Spinner from "../../components/Common/Spinner";
import RightToInformationFile from "../../components/Common/RightToInformationFile";
import CollectionNewsListBox from "../../components/Common/CollectionNewsListBox";
import { API_URL, header,APP_NAME, FB_ID } from "../../utils/config";
import ErrorBoundary from "../../components/Error/ErrorBoundary";
import axios from "axios";
import Pagination from "react-js-pagination";
import { MetaHead } from '../../components/Common/MetaHead.js';

const RightToInformation = (props) => {
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(1);
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(3);
  const [rightToInfo, setRightToInfo] = useState();
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  const handlePageChange = (pageNumber) => {
    axios
      .get(`${API_URL}api/right_to_info?page=` + pageNumber)
      .then((res) => {
        setRightToInfo(res.data.data);
        setActivePage(res.data.current_page);
        setItemsCountPerPage(res.data.per_page);
        setTotalItemsCount(res.data.total);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setError(true);
      });
  };

  useEffect(() => {
    loadRightToInfo();
  }, []);

  const loadRightToInfo = () => {
    axios
      .get(`${API_URL}api/right_to_info`, header)
      .then((res) => {
        setRightToInfo(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setError(true);
      });
  };

  const content = () => {
    if (loader) return <Spinner />;
    if (error) return <ErrorBoundary />;
    return (
      <RightToInformationFile
        data={rightToInfo.data}
        engLang={props.engLang}
        type="press"
      />
    );
  };

  

  return (
    <RightToInformationSection>
      <Row>
        <Col xs={12} md={8} lg={8}>
          <EachPageHeading
            title={props.engLang ? "Right To Information" : "सूचनाको-हक"}
          />
          <p className="lead">Download the attachments.</p>
          <MetaHead page_name="सूचनाको हक"  description="जिल्ला हुलाक कार्यालय सल्यान को  सूचनाको हक हेर्न सक्नु हुन्छ " page_title="जिल्ला हुलाक कार्यालय सल्यान को  सूचनाको हक" page_url="/media/right-to-information"/>
      
          {content()}
          <div className="d-flex justify-content-center">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              pageRangeDisplayed={pageRangeDisplayed}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </Col>
        <Col>
          <CollectionNewsListBox type="News & Notice" engLang={props.engLang} />
        </Col>
      </Row>
    </RightToInformationSection>
  );
};

export default RightToInformation;
