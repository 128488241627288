import React from "react";
import { Table } from "react-bootstrap";
import { ReactComponent as Download } from "../../assets/images/download.svg";
import { API_URL, header } from "../../utils/config";

const RightToInformationFile = ({ data, engLang }) => {
  return (
    <div>
      {header && <h4 style={{ textAlign: "center" }}>{header}</h4>}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{engLang ? "S.N." : "सि.नं."}</th>
            <th>{engLang ? "Title" : "शिर्षक"}</th>
            <th>{engLang ? "Time Period" : "समय अवधि"}</th>
            <th>{engLang ? "Download" : "डाउनलोड"}</th>
          </tr>
        </thead>
        <tbody>
          {data
            ? data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span style={{ fontSize: "12px" }}>{index + 1}</span>
                    </td>
                    <td>{item.title}</td>
                    <td>
                      {item.first_date}/{item.last_date}
                    </td>
                    <td>
                      <a href={`${API_URL} ${item.file}`} download>
                        <Download width={40} height={40} className="mr-1" />
                        <span style={{ fontSize: "12px" }}></span>
                      </a>
                    </td>
                  </tr>
                );
              })
            : "No Data Available"}
        </tbody>
      </Table>
    </div>
  );
};

export default RightToInformationFile;
